body {
  * {
    h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
      font-weight: 300;
      //font-family: $font-family-sans-serif;

      .label {
        font-size: 11px;
        font-weight: bold;
      }

      small {
        font-size: 0.75em;
      }
    }
  }
}

.transform-none {
  transform: none !important;
}


a {
  transition: $transition-base;
  color: $font-color-base;
}


h1 {
  letter-spacing: -1px;
  margin: 10px 0;
}

h2 {
  letter-spacing: -1px;
  margin: 20px 0;
  line-height: normal;
}

h3 {
  display: block;
  font-weight: 400;
  margin: 20px 0;
  line-height: normal;
}

h4 {
  line-height: normal;
}

h5 {
  font-weight: 300;
  margin: 10px 0;
  line-height: normal;
}


.lead {
  margin-bottom: 18px;
  font-size: 14px;
  font-weight: 300;
  line-height: 1.4;
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}


.text-error {
  color: var(--danger);
}

.text-warning {
  color: var(--warning);
}


@include media-breakpoint-down('sm') {

  h2, .h2 {
    font-size: 2em;
  }
}
