// activity

label.label {
  display: block;
  margin-bottom: 0px;
  line-height: 19px;
  font-weight: 400;
  font-size: 11px;
  color: #333;
  text-align: left;
  white-space: normal;
  padding: 0;
}


select,
{
  display: block;
  box-sizing: border-box;
  width: 100%;
  height: 34px;
  line-height: 34px;
  padding: 5px 10px;
  outline: 0;
  border-width: 1px;
  border-style: solid;
  border-radius: 0;
  background: #fff;
  font: 13px/16px 'Open Sans', Helvetica, Arial, sans-serif;
  color: #404040;
  appearance: normal;

  &.is-invalid {
    background: #fff0f0;
    border-color: $danger;
  }

  &:focus {
    @include focus();
  }
}

textarea {
  margin-bottom: 10px;
  width: 100%;
  max-height: 300px;
  min-height: 126px;
  background: rgba(255, 255, 255, 0.4);
  border-color: #bdbdbd;
  padding: 5px 10px;

  &:focus {
    background-color: #f5f7f6;
    border-color: #53565a;
    outline: 0;
    box-shadow: inset 0 1px 1px rgba(68, 68, 68, 0.075), inset 0 1px 1px rgba(68, 68, 68, 0.075), 0 0 0 3px rgba(83, 86, 90, 0.2);
  }

}

.text-block,
.tip-box {
  max-width: 824px;
}


input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-box-shadow: 0 0 0px 1000px #fff inset;
}

input.input, textarea.textearea, select.select {
  font: 13px/16px 'Open Sans', Helvetica, Arial, sans-serif;
  color: #404040;


  &[readonly] {
    background-color: #f5f7f6 !important;
  }

}

input.input {
  display: block;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  width: 100%;
  height: 34px;
  line-height: 34px;
  padding: 5px 10px;
  outline: 0;
  border-width: 1px;
  border-style: solid;
  border-radius: 0;
  background: #fff;
  appearance: normal;
  border-color: #bdbdbd;

  &.is-invalid {
    background: #fff0f0 !important;
    border-color: $danger !important;

  }

  &:focus,
  &:hover {
    outline: -webkit-focus-ring-color auto 5px;
  }

  &:hover {
    outline-color: #bdbdbd;
  }

  &:focus {
    //color: #74708d;
    background-color: #f5f7f6;
    border-color: #53565a;
    outline: 0;
    box-shadow: inset 0 1px 1px rgba(68, 68, 68, 0.075), inset 0 1px 1px rgba(68, 68, 68, 0.075), 0 0 0 3px rgba(83, 86, 90, 0.2);
  }

  &::-webkit-input-placeholder,
  &:-ms-input-placeholder,
  &::-moz-placeholder,
  &:-moz-placeholder {
    opacity: .5;
  }


  &:-webkit-autofill {
    &:hover,
    &:focus,
    &:active {
      transition-delay: 9999s;
      transition-property: background-color, color;
    }
  }
}


.note {
  margin-top: 6px;
  padding: 0 1px;
  font-size: 11px;
  line-height: 15px;
  color: #999;

  &.state-error {
    color: $danger;
  }
}


//gl-input + .note {
//  //position: absolute;
//  //margin-top: 0px;
//}
//
//
//gl-input {
//  &.is-invalid {
//    input {
//      background: #fff0f0;
//      border-color: $danger;
//    }
//  }
//}
//


.icon-append,
.icon-prepend {
  //border-color: #bdbdbd;
  color: #A2A2A2;
  transition: border-color .3s;
  z-index: 1;
}

.icon-prepend {
  margin-left: 5px;
  padding-right: 3px;
  //border-right-width: 1px;
  //border-right-style: solid;
}

.icon-append,
.icon-prepend {
  font-size: 14px;
  margin-right: 5px;
}
