$focus-bg-color: #f5f7f6;

@mixin focus {
  color: #53565a;
  background-color: $focus-bg-color;
  border-color: #53565a;
  outline: 0;
  box-shadow: inset 0 1px 1px rgba(68, 68, 68, 0.075),
  inset 0 1px 1px rgba(68, 68, 68, 0.075), 0 0 0 3px rgba(83, 86, 90, 0.2);
}
