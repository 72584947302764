.select2-dropdown {
    border-radius: 0;
}

input.select2-search__field {
    margin-top: 0;
    margin-bottom: 0;

    &:focus {
        outline: 0;
        box-shadow: none !important;
    }
}

.select2-results__option {
    font-size: 15px;
}

