@import "~ag-grid-community/dist/styles/ag-grid.css";
@import "~ag-grid-community/dist/styles/ag-theme-balham.css";


.ag-root {
  cursor: default;
  font-size: 13px;
  color: #444 !important;
  overflow: auto;


  .is-invalid {
    background-color: #fff0f0;
    border: thin solid $danger;
    //border-right: none;
    //border-left: none;

    &:not(:last-child) {
      border-right-color: transparent !important;
}

    &:not(:first-child) {
      border-left-color: transparent !important;
    }
  }
}

.cell-wrap-text {
  white-space: normal !important;
}

.ag-header {
  background-color: #f5f5f5;

  .ag-header-cell {
    background-color: white;
  }
}

.ag-header-row .ag-header-cell {
  &.no-borders {
    .ag-header-cell-resize {
      border: none !important;
    }
  }

  &:last-child {
    &:after {
      border-right: none;
    }
  }
}

.ag-cell {
  border-top: 1px solid #ddd;
  vertical-align: middle;

  &.ag-cell-value {
    &:before {
      content: '';
      display: inline-block;
      height: 100%;
      vertical-align: middle;
      position: absolute;
    }

    &.is-fa {
      div {
        display: inline-block;
        padding-top: 5px;
      }
    }
  }

  &.has-text-centered {
    text-align: center;
  }

  &.is-checkbox {
    padding-top: 10px;
  }

  &.editing-allowed {
    &:before {
      border: thin dashed lighten(#444444, 40%);
      height: 100%;
      width: 100%;
      left: 0;
    }
  }

}

.ag-header-cell,
.ag-cell {
  padding-left: 10px;
  padding-right: 10px;
}

.text-center,
.header-tx-center {
  .ag-header-cell-text,
  .ag-header-group-text {
    text-align: center;
    display: block;
  }

  .ag-header-group-cell,
  .ag-header-cell {
    box-sizing: border-box;
    vertical-align: bottom;
    text-align: center;
    display: inline-block;
    height: 100%;
    position: absolute;
  }

  .ag-header-group-cell-label,
  .ag-header-cell-label {
    justify-content: center;
  }
}

.text-right {
  text-align: right !important;
  justify-content: flex-end;

  .ag-header-cell-label {
    justify-content: flex-end;
  }
}

::ng-deep ag-grid-angular {
  &.ag-theme-balham .ag-header-cell-label {
    //width: auto;
  }
}


.ag-header-cell-sortable {
  cursor: pointer;
}

.ag-row {
  &.ag-row-hover {
    cursor: default;
    background-color: #eee !important;;
  }

  &.ag-row-selected {
    background-color: #eee !important;
    //color: #00467f !important;
    border-bottom-color: #d9dcde !important;
    border-color: #d9dcde;

  }
}

.ag-menu {
  padding: 5px;
  box-shadow: $whiteframe-shadow-1dp;

  .ag-filter-select {
    margin: 0 0 6px 0;
  }

  select,
  input {
    display: block;
    width: 100%;
    padding: 0.5625rem 1.2em;
    font-size: 0.875rem;
    line-height: 1.57143;
    color: #74708d;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #e4e9f0;
    border-radius: 0.25rem;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  }
}


.ag-theme-balham .ag-header {
  font-size: 14px !important;
  font-weight: normal !important;
  color: #333 !important;
}
