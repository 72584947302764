@-webkit-keyframes pulse {
  0% {
    -webkit-transform: scale(1)
  }
  50% {
    -webkit-transform: scale(1.1)
  }
  100% {
    -webkit-transform: scale(1)
  }
}

@-moz-keyframes pulse {
  0% {
    -moz-transform: scale(1)
  }
  50% {
    -moz-transform: scale(1.1)
  }
  100% {
    -moz-transform: scale(1)
  }
}

@-o-keyframes pulse {
  0% {
    -o-transform: scale(1)
  }
  50% {
    -o-transform: scale(1.1)
  }
  100% {
    -o-transform: scale(1)
  }
}

@keyframes pulse {
  0% {
    transform: scale(1)
  }
  50% {
    transform: scale(1.1)
  }
  100% {
    transform: scale(1)
  }
}

.animated.pulse {
  -webkit-animation-name: pulse;
  -moz-animation-name: pulse;
  -o-animation-name: pulse;
  animation-name: pulse
}

@-webkit-keyframes flip {
  0% {
    -webkit-transform: perspective(400px) translateZ(0) rotateY(0) scale(1);
    -webkit-animation-timing-function: ease-out
  }
  40% {
    -webkit-transform: perspective(400px) translateZ(150px) rotateY(170deg) scale(1);
    -webkit-animation-timing-function: ease-out
  }
  50% {
    -webkit-transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
    -webkit-animation-timing-function: ease-in
  }
  80% {
    -webkit-transform: perspective(400px) translateZ(0) rotateY(360deg) scale(0.95);
    -webkit-animation-timing-function: ease-in
  }
  100% {
    -webkit-transform: perspective(400px) translateZ(0) rotateY(360deg) scale(1);
    -webkit-animation-timing-function: ease-in
  }
}

@-moz-keyframes flip {
  0% {
    -moz-transform: perspective(400px) translateZ(0) rotateY(0) scale(1);
    -moz-animation-timing-function: ease-out
  }
  40% {
    -moz-transform: perspective(400px) translateZ(150px) rotateY(170deg) scale(1);
    -moz-animation-timing-function: ease-out
  }
  50% {
    -moz-transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
    -moz-animation-timing-function: ease-in
  }
  80% {
    -moz-transform: perspective(400px) translateZ(0) rotateY(360deg) scale(0.95);
    -moz-animation-timing-function: ease-in
  }
  100% {
    -moz-transform: perspective(400px) translateZ(0) rotateY(360deg) scale(1);
    -moz-animation-timing-function: ease-in
  }
}

@-o-keyframes flip {
  0% {
    -o-transform: perspective(400px) translateZ(0) rotateY(0) scale(1);
    -o-animation-timing-function: ease-out
  }
  40% {
    -o-transform: perspective(400px) translateZ(150px) rotateY(170deg) scale(1);
    -o-animation-timing-function: ease-out
  }
  50% {
    -o-transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
    -o-animation-timing-function: ease-in
  }
  80% {
    -o-transform: perspective(400px) translateZ(0) rotateY(360deg) scale(0.95);
    -o-animation-timing-function: ease-in
  }
  100% {
    -o-transform: perspective(400px) translateZ(0) rotateY(360deg) scale(1);
    -o-animation-timing-function: ease-in
  }
}

@keyframes flip {
  0% {
    transform: perspective(400px) translateZ(0) rotateY(0) scale(1);
    animation-timing-function: ease-out
  }
  40% {
    transform: perspective(400px) translateZ(150px) rotateY(170deg) scale(1);
    animation-timing-function: ease-out
  }
  50% {
    transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
    animation-timing-function: ease-in
  }
  80% {
    transform: perspective(400px) translateZ(0) rotateY(360deg) scale(0.95);
    animation-timing-function: ease-in
  }
  100% {
    transform: perspective(400px) translateZ(0) rotateY(360deg) scale(1);
    animation-timing-function: ease-in
  }
}

.animated.flip {
  -webkit-backface-visibility: visible !important;
  -webkit-animation-name: flip;
  -moz-backface-visibility: visible !important;
  -moz-animation-name: flip;
  -o-backface-visibility: visible !important;
  -o-animation-name: flip;
  backface-visibility: visible !important;
  animation-name: flip
}

@-webkit-keyframes flipInX {
  0% {
    -webkit-transform: perspective(400px) rotateX(90deg);
    opacity: 0
  }
  40% {
    -webkit-transform: perspective(400px) rotateX(-10deg)
  }
  70% {
    -webkit-transform: perspective(400px) rotateX(10deg)
  }
  100% {
    -webkit-transform: perspective(400px) rotateX(0deg);
    opacity: 1
  }
}

@-moz-keyframes flipInX {
  0% {
    -moz-transform: perspective(400px) rotateX(90deg);
    opacity: 0
  }
  40% {
    -moz-transform: perspective(400px) rotateX(-10deg)
  }
  70% {
    -moz-transform: perspective(400px) rotateX(10deg)
  }
  100% {
    -moz-transform: perspective(400px) rotateX(0deg);
    opacity: 1
  }
}

@-o-keyframes flipInX {
  0% {
    -o-transform: perspective(400px) rotateX(90deg);
    opacity: 0
  }
  40% {
    -o-transform: perspective(400px) rotateX(-10deg)
  }
  70% {
    -o-transform: perspective(400px) rotateX(10deg)
  }
  100% {
    -o-transform: perspective(400px) rotateX(0deg);
    opacity: 1
  }
}

@keyframes flipInX {
  0% {
    transform: perspective(400px) rotateX(90deg);
    opacity: 0
  }
  40% {
    transform: perspective(400px) rotateX(-10deg)
  }
  70% {
    transform: perspective(400px) rotateX(10deg)
  }
  100% {
    transform: perspective(400px) rotateX(0deg);
    opacity: 1
  }
}

.animated.flipInX {
  -webkit-backface-visibility: visible !important;
  -webkit-animation-name: flipInX;
  -moz-backface-visibility: visible !important;
  -moz-animation-name: flipInX;
  -o-backface-visibility: visible !important;
  -o-animation-name: flipInX;
  backface-visibility: visible !important;
  animation-name: flipInX
}

@-webkit-keyframes flipOutX {
  0% {
    -webkit-transform: perspective(400px) rotateX(0deg);
    opacity: 1
  }
  100% {
    -webkit-transform: perspective(400px) rotateX(90deg);
    opacity: 0
  }
}

@-moz-keyframes flipOutX {
  0% {
    -moz-transform: perspective(400px) rotateX(0deg);
    opacity: 1
  }
  100% {
    -moz-transform: perspective(400px) rotateX(90deg);
    opacity: 0
  }
}

@-o-keyframes flipOutX {
  0% {
    -o-transform: perspective(400px) rotateX(0deg);
    opacity: 1
  }
  100% {
    -o-transform: perspective(400px) rotateX(90deg);
    opacity: 0
  }
}

@keyframes flipOutX {
  0% {
    transform: perspective(400px) rotateX(0deg);
    opacity: 1
  }
  100% {
    transform: perspective(400px) rotateX(90deg);
    opacity: 0
  }
}

.animated.flipOutX {
  -webkit-animation-name: flipOutX;
  -webkit-backface-visibility: visible !important;
  -moz-animation-name: flipOutX;
  -moz-backface-visibility: visible !important;
  -o-animation-name: flipOutX;
  -o-backface-visibility: visible !important;
  animation-name: flipOutX;
  backface-visibility: visible !important
}

@-webkit-keyframes flipInY {
  0% {
    -webkit-transform: perspective(400px) rotateY(90deg);
    opacity: 0
  }
  40% {
    -webkit-transform: perspective(400px) rotateY(-10deg)
  }
  70% {
    -webkit-transform: perspective(400px) rotateY(10deg)
  }
  100% {
    -webkit-transform: perspective(400px) rotateY(0deg);
    opacity: 1
  }
}

@-moz-keyframes flipInY {
  0% {
    -moz-transform: perspective(400px) rotateY(90deg);
    opacity: 0
  }
  40% {
    -moz-transform: perspective(400px) rotateY(-10deg)
  }
  70% {
    -moz-transform: perspective(400px) rotateY(10deg)
  }
  100% {
    -moz-transform: perspective(400px) rotateY(0deg);
    opacity: 1
  }
}

@-o-keyframes flipInY {
  0% {
    -o-transform: perspective(400px) rotateY(90deg);
    opacity: 0
  }
  40% {
    -o-transform: perspective(400px) rotateY(-10deg)
  }
  70% {
    -o-transform: perspective(400px) rotateY(10deg)
  }
  100% {
    -o-transform: perspective(400px) rotateY(0deg);
    opacity: 1
  }
}

@keyframes flipInY {
  0% {
    transform: perspective(400px) rotateY(90deg);
    opacity: 0
  }
  40% {
    transform: perspective(400px) rotateY(-10deg)
  }
  70% {
    transform: perspective(400px) rotateY(10deg)
  }
  100% {
    transform: perspective(400px) rotateY(0deg);
    opacity: 1
  }
}

.animated.flipInY {
  -webkit-backface-visibility: visible !important;
  -webkit-animation-name: flipInY;
  -moz-backface-visibility: visible !important;
  -moz-animation-name: flipInY;
  -o-backface-visibility: visible !important;
  -o-animation-name: flipInY;
  backface-visibility: visible !important;
  animation-name: flipInY
}

@-webkit-keyframes flipOutY {
  0% {
    -webkit-transform: perspective(400px) rotateY(0deg);
    opacity: 1
  }
  100% {
    -webkit-transform: perspective(400px) rotateY(90deg);
    opacity: 0
  }
}

@-moz-keyframes flipOutY {
  0% {
    -moz-transform: perspective(400px) rotateY(0deg);
    opacity: 1
  }
  100% {
    -moz-transform: perspective(400px) rotateY(90deg);
    opacity: 0
  }
}

@-o-keyframes flipOutY {
  0% {
    -o-transform: perspective(400px) rotateY(0deg);
    opacity: 1
  }
  100% {
    -o-transform: perspective(400px) rotateY(90deg);
    opacity: 0
  }
}

@keyframes flipOutY {
  0% {
    transform: perspective(400px) rotateY(0deg);
    opacity: 1
  }
  100% {
    transform: perspective(400px) rotateY(90deg);
    opacity: 0
  }
}

.animated.flipOutY {
  -webkit-backface-visibility: visible !important;
  -webkit-animation-name: flipOutY;
  -moz-backface-visibility: visible !important;
  -moz-animation-name: flipOutY;
  -o-backface-visibility: visible !important;
  -o-animation-name: flipOutY;
  backface-visibility: visible !important;
  animation-name: flipOutY
}
