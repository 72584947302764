.cdk-overlay-container {
  z-index: 1051;
}

.mat-dialog-container-p-0 > mat-dialog-container {
  padding: 0 !important;
}

.mat-dialog-datepicker {
  //visibility: hidden;

  > mat-dialog-container {
    border-radius: 0 !important;
    padding: 0 !important;
  }
}
