/* You can add global styles to this file, and also import other style files */
@import '~bootstrap/scss/bootstrap';
@import './scss/material-design/whiteframe';
@import './scss/material-design/overlay';
@import '~air-datepicker/air-datepicker.css';
@import '~select2/dist/css/select2.css';
@import '~balloon-css/balloon';

@import './scss/variables';
@import './scss/vendors/bourbon';
@import './scss/animation/animation';
@import './scss/scrollbar';
@import './scss/typography';
@import './scss/ag-grid';

//base
@import './scss/base/mixins';
@import './scss/base/table';
@import './scss/base/base';
@import './scss/base/modal';
@import './scss/base/btn';
@import './scss/base/helpers';
@import './scss/base/fieldset';
@import './scss/base/forms';
@import './scss/base/alert';
@import './scss/base/pace';
@import './scss/base/select2';
@import './scss/base/animations';
@import './scss/base/widget';
@import './scss/base/tabset';
@import '~@angular/material/prebuilt-themes/indigo-pink.css';

@import './scss/daterangepicker.scss';

login {
    background-color: $white;
    display: flex;
    height: 100%;

    toast {
        top: #{$header-height + 10px};
    }

    footer {
        height: 60px;
        width: 100%;
        border-top: 2px solid darken($primary-one, 10%);
    }
    .login-left-inner {
        max-width: em(520);
        margin-left: em(60);
        padding-bottom: em(20);
        position: fixed;
        bottom: 0;
        left: 0;

        p {
            small {
                font-size: 1em;
                font-weight: 300;
                line-height: 2em;
            }
        }
    }

    .login-container {
        width: 400px;
        margin: 10% auto;

        .widget-box {
            border-bottom: none;
            box-shadow: none;
            padding: 6px;
            //background-color: darken($primary-one, 10%);
            visibility: visible;
            position: relative;
            z-index: auto;
            transform: scale(1, 1) translate(0);
            transition: transform 0.3s ease, -webkit-transform 0.3s ease;
        }

        .widget-body {
            background-color: #fff;
        }

        .widget-box .widget-main {
            padding: 20px 20px 10px;
        }

        .toolbar {
            height: 91px;
            display: block;
            padding: 13px 0 0;
            border-bottom: 1px dashed rgba(0, 0, 0, 0.2);
            background: #fff;
            font-size: 16px;
            font-weight: 300;
            color: #232323;
            margin: 0px 36px -7px;
        }
    }

    .forgotpassword {
        &:hover {
            color: $primary-one !important;
        }
    }

    .signin-text {
        font-size: 42px;
        text-align: center;
    }

    .button {
        background-color: $primary-one;
        color: #fff;

        &:hover {
            background-color: darken($primary-one, 10%);
            border-color: transparent;
            transition: border 0.5s;
        }

        float: right;
        align-items: center;
        border: 3px solid darken($primary-one, 10%);
        border-radius: 0;
        box-shadow: none;
        display: inline-flex;
        font-size: 1rem;
        height: 2.25em;
        line-height: 1.5;
        position: relative;
        vertical-align: top;
        cursor: pointer;
        justify-content: center;
        text-align: center;
        white-space: nowrap;
    }
}

context-menu {
    display: block;

    > ul {
        min-width: 225px;
    }

    .x-context-menu {
        position: absolute;
        width: auto;
        box-shadow: -2px 2px 5px 0px rgba(0, 0, 0, 0.5);
        padding: 5px 0;
        z-index: 1050;

        li {
            width: 100%;
            float: none;
            display: table;
            position: relative;
            background-color: $white;
            padding: 0 15px;
            cursor: pointer;

            a {
                font-size: 17px;
                height: 35px;
                display: table-cell;
                width: 100%;
                vertical-align: middle;
                text-align: left;
                white-space: nowrap;

                i {
                    float: left;
                    margin: 5px 15px 0 0;
                    width: 15px;
                    text-align: center;
                }
            }

            &:hover {
                background-color: #f1f5f9;

                a {
                    color: #4b88b7;
                }
            }

            &.list-divider:last-child {
                display: none;
            }
        }
    }

    .list-divider {
        border-top: 1px solid #c4c4c4;
        margin: 5px 0;
    }

    .list-icon {
        margin: 0px 10px 0 0;
    }
}

sidebar {
    /* Expand Vertical Sidebar
========================*/
    @media (min-width: #{$theme-breakpoint + 1px}) {
        .site-sidebar:not(.is-collapse) {
            width: $sidebar-width;
        }
    }

    .is-collapse {
        @import './scss/collapse';
        @include breakpoint-sidebar;
    }

    .list-divider {
        padding: 1px;
        pointer-events: none;

        + .list-divider {
            display: none;
        }
    }

    .site-sidebar {
        overflow: hidden;
        height: 100%;
        transition: $transitions-speed;
        width: $sidebar-width;
        left: 0;
        background: $sidebar-bg;
        border-right: 1px solid $theme-border-color;
    }

    ul {
        flex-direction: column;
        position: relative;
    }

    .side-menu {
        font-size: em(13);

        .nav-icon {
            width: 40px;
            position: absolute;
            left: 10px;

            + .menu-description {
                padding-left: 50px;
            }
        }

        li:not(.collapse-control):not(.is-child) {
            position: relative;

            &:before {
                pointer-events: none;
                content: '';
                transition: $transitions-speed;
                width: $sidebar-width;
                position: absolute;
                height: 100%;
                left: -1px;
                top: 0;
            }

            &:not(:first-child) {
                &:before {
                    border-top: thin solid #cccccc;
                }
            }

            a {
                position: relative;
                padding: em(15, 15);
                font-size: em(15, 13);

                &:focus {
                    outline: none;
                    box-shadow: none;
                }
            }

            &:hover,
            &.active {
                > a {
                    color: #00467f;
                }

                &:before {
                    background: #eee;
                }
            }

            &.active {
                > a {
                    border-left: 3px solid $color-scheme;
                }

                .sub-menu {
                    display: flex;
                }
            }
        }

        .sub-menu {
            transition: max-height $transitions-speed ease-in-out;
            max-height: 600px;
            background: #fff;
            display: none;

            li {
                &:before {
                    border: none !important;
                }

                &:hover:before {
                    background: #eee !important;
                }

                &:hover,
                &.active {
                    //background: #eee !important;
                    //&:before {
                    //  content: "";
                    //  width: 100%;
                    //  height: 75%;
                    //  top: 15%;
                    //  position: absolute;
                    //}

                    > a {
                        text-decoration: underline !important;
                        color: #00467f;
                        border-left: none !important;
                    }
                }

                > a {
                    padding-top: 10px !important;
                    padding-bottom: 10px !important;
                }
            }
        }

        .menu-item-has-children .sub-menu {
            a {
                padding-left: 3em;
            }
        }
    }

    .collapse-control {
        height: 45px;
        border-bottom: none;

        .minifyme {
            border-bottom: 1px solid #302f2f;
            background: $primary-two;
            color: $primary-three;
            text-align: center;
            padding: 0;
            width: 36px;
            height: 28px;
            display: block;
            position: absolute;
            right: 0;
            border-radius: 5px 0 0 5px;
            cursor: pointer;
            margin-top: 7px;
            font-size: 19px;
            transition: all 0.1s linear 0s;

            &:hover {
                background: lighten($primary-two, 10%);
                color: #e4e4e4;
                right: 0;
                width: 40px;
                padding-right: 5px;
            }
        }

        &:after {
            background-color: transparent;
        }
    }
}
sidebar-template {
    nav {
        width: $sidebar-width;
        transition: $transitions-speed;

        &.sidebar-collapse {
            width: $sidebar-collapse-width;
        }
    }

    main > div {
        overflow-x: hidden;
        overflow-y: auto;
    }
}

user {
    display: flex;
    padding: 3px;

    .user-circle {
        width: #{$secondary-header-height - 5px};
        height: #{$secondary-header-height - 5px};
        border: thin solid $xenial-50-grey;
        border-radius: $secondary-header-height;
        background-color: $xenial-50-grey;

        font-size: 21px;
        text-align: center;
        line-height: #{$secondary-header-height - 7px};
        color: $primary-two;
    }
}

app-header {
    .navbar {
        border-bottom: thin solid $xenial-50-grey;
        background-color: $white;
        flex-direction: column;
        display: flex;
        z-index: 10;
    }

    .app-name {
        font-size: 24px;
        border-left: thin solid $xenial-50-grey;
        padding-left: 5px;
    }
}

.navbar {
    .navbar-brand {
        color: $font-color-base;
        margin-right: 50px;
        font-size: 1rem;
        padding: 0;

        .guestlist-svg {
            height: $header-height;

            + .payroll {
                font-size: 25px;
                left: 130px;
                top: 10px;
            }
        }
    }
}

.brand-logo {
    background-image: url('/assets/img/heartland.svg');
    color: transparent;
    background-repeat: no-repeat;
    height: $header-height;
    width: 165px;
    text-align: center;
    background-position: center;
    background-size: contain;
    margin-top: 0px;
    margin-left: 15px;

    &:active,
    &:focus {
        outline: none;
    }
}

app-footer {
    display: flex;
    width: 100%;
    z-index: 1;
    background: #fff;

    footer {
        width: 100%;
        z-index: 1050;
        background-color: #fff;
        border-top: 2px solid darken($primary-one, 10%);
    }
}

.version {
    color: $xenial-50-grey;
    transition: color 0.5s ease;

    &:hover {
        color: $primary-two;
    }
}

app-drawer-animate {
    display: block;
    width: 330px;
    position: absolute;
    right: 5px;
    top: -100%;
    transition: top 1s ease;
    z-index: 1040;

    &.open {
        top: #{$header-height + 5};
    }

    .application {
        height: 100%;
        background-color: #ffffff;
        display: flex;
        flex-direction: column;
    }
}

.app-drawer {
    height: 100%;
}

site-selection-grid {
    display: flex;
    flex-direction: column;
    width: 300px;
    height: 300px;
    background-color: #ffffff;

    .app-header {
        background-color: #d9d9d9;
        color: $primary-two;
        padding: 5px 10px;
        font-weight: 700;
        font-size: 15px;
        height: 35px;
        user-select: none;

        button {
            display: inline-block;
            cursor: pointer;
            float: right;
        }
    }

    search-bar {
        width: 100%;

        label {
            margin: 0 !important;
        }
    }
}

site-header {
    display: flex;

    .site-group {
        border-right: thin solid $xenial-50-grey;
        border-left: thin solid $xenial-50-grey;
        width: 235px;
    }

    .site-icon {
        height: 43px;
        width: 35px;
        text-align: center;
        vertical-align: middle;
        font-size: 25px;
        display: table;
        color: $xenial-50-grey;
        margin-right: -1px;
    }

    .site-description {
        height: calc(100% - 15px);
        vertical-align: middle;
        text-align: left;
        width: 165px;

        .site_name {
            font-weight: bold;
        }
    }

    .site_selection {
        text-decoration: underline;
        color: $xenial-30-grey;
        text-align: left;
        font-size: 10px;
        width: 100%;
        height: 15px;

        > span {
            cursor: pointer;

            &:hover {
                color: #444444;
            }
        }
    }
}

hoursofoperations {
    .icon-rule {
        width: 20px;
        height: 20px;
        display: inline-block;
        margin-left: 2px;
        margin-top: 6px;
        text-align: center;
        cursor: pointer;
    }

    .rule-icon {
        min-width: 40px;
    }

    .rule-locale {
        width: 200px;
    }

    .position-icon {
        opacity: 0.5;

        &:hover {
            opacity: 1;
        }
    }

    .delete-icon {
        color: $danger !important;
    }

    .icon-rule {
        width: 14px;
    }

    .rule-move-up {
        margin-left: 5px;
    }

    .rule-move-down {
        margin-left: -3px;
    }

    table {
        min-width: 700px;
    }
}

user-list {
    gl-article {
        height: 100%;
    }

    gl-input {
        &.username {
            input {
                text-transform: lowercase;
            }
        }

        &.text-uppercase input {
            text-transform: uppercase;
        }

        &.site-selection-input {
            gl-input-input {
                input {
                    padding-right: 110px;
                }
            }
        }
    }

    clipboard {
        position: absolute;

        .btn-clipboard {
            padding: 0px 10px;
            border-radius: 0;
            z-index: 1;
        }

        .highlight {
            padding: 0;
            height: 0;
        }
    }

    .sitemaps {
        display: inline-block;
        vertical-align: top;
        position: relative;
        width: 45%;

        .makeDefault {
            display: none;
        }
    }
}

.new-user-information,
.application-sitemaps {
    width: 100%;
    min-height: 500px;
}

.application-components {
    width: 100%;
    @include media-breakpoint-up('lg') {
        width: 260px;
        padding-left: 15px;
    }
}

capi-dashboard {
    display: flex;
    flex-direction: column;
    height: 100%;

    article-controls {
        padding: 4px;
        display: inline-block;
    }

    gl-page-footer {
        transition: all 1s ease;

        &:not(.show) {
            bottom: -30px;
        }
    }
}

pos-app {
    toast {
        top: #{$header-height + 10px} !important;
    }
}

.apps-drawer {
    .flex-container {
        padding: 0;
        margin: 0;
        list-style: none;
        display: flex;
        min-height: 87px;

        &:focus {
            outline: none;
        }
    }

    .clickable-item {
        position: absolute;
        height: 100%;
        width: 100%;
        left: 0;
        top: 0;
        z-index: 5;
        pointer-events: all;
    }

    .wrap {
        -webkit-flex-wrap: wrap;
        flex-wrap: wrap;
    }

    .flex-item {
        background-color: #ffffff;
        text-align: center;
        border-radius: 3px;
        position: relative;
        cursor: pointer;
        font-size: 12px;
        padding: 5px;
        margin: 10px;
        width: 86px;
        user-select: none;
        border: none;

        &.placeholder {
            background-color: lighten(#d9d9d9, 10%);
            pointer-events: none;
        }

        &.draggable--over,
        &.draggable-source--is-dragging {
            visibility: hidden;
        }

        &.draggable-mirror {
            cursor: move;
        }

        .icon {
            font-size: 25px;
        }

        &:hover {
            background-color: lighten($xenial-50-grey, 10%);
            //color: #ffffff;
        }

        &.active {
            //background-color: #9CC467;
            background-color: $tangerine;
            box-shadow: $whiteframe-shadow-1dp;
            color: #ffffff;
        }

        &.active {
            .icon-selection-default {
                display: block;
            }
        }

        &:focus {
            outline: none;
        }

        &[aria-disabled='true'],
        &[disabled='true'],
        &.disabled {
            background-color: unset;
            color: unset;
            opacity: 0.3;
            cursor: not-allowed;
        }
    }

    .appended-element {
        visibility: hidden;
    }

    collapsible {
        label {
            user-select: none;
        }
    }

    .app-header {
        background-color: #d9d9d9;
        color: $primary-two;
        padding: 5px 10px;
        font-weight: 700;
        font-size: 15px;
        height: 35px;
        user-select: none;
    }

    .editable {
        display: inline-block;
        cursor: pointer;
        float: right;
    }

    &.is-draggabilly {
        .flex-item {
            cursor: move;
        }
    }
}

apps-modules {
    .flex-container {
        padding: 0;
        margin: 0;
        list-style: none;
        display: flex;
    }

    .wrap {
        -webkit-flex-wrap: wrap;
        flex-wrap: wrap;
    }

    .flex-item {
        padding: 5px;
        width: 86px;
        margin: 10px;
        text-align: center;
        border-radius: 3px;
        position: relative;

        .icon {
            font-size: 25px;
        }

        cursor: pointer;
        font-size: 12px;

        &:hover,
        &.active {
            background-color: $tangerine;
            color: #ffffff;
        }

        &.active {
            .icon-selection-default {
                display: block;
            }
        }

        &:focus {
            outline: none;
        }

        &[aria-disabled='true'],
        &[disabled='true'],
        &.disabled {
            background-color: unset;
            color: unset;
            opacity: 0.3;
            cursor: not-allowed;
        }

        &:hover {
            .set_default_identifier {
                display: block;
                color: $primary-two;
            }
        }

        &.active {
            .set_default_identifier {
                &._default {
                    display: block;
                    color: var(--success);
                }
            }
        }
    }

    .appended-element {
        visibility: hidden;
    }

    .set_default_identifier {
        background-color: #ffffff;
        display: none;

        border-radius: 55px;
        position: absolute;
        font-size: 22px;
        height: 23px;
        right: -10px;
        top: -14px;
    }
}

gl-page-footer {
    position: fixed;

    display: block;
    bottom: 24px;
    width: 100%;
    z-index: 1;
    left: 0;
    padding-left: $sidebar-width;
    transition: $transitions-speed;

    .sidebar-collapsed {
        padding-left: $sidebar-collapse-width;
    }

    .gp-footer {
        border-top: thin solid $xenial-50-grey;
        background-color: #fff;
        padding: 10px 20px;
    }
}

settings {
    display: flex;
    flex-direction: row;

    background-color: transparent;

    position: absolute;
    min-height: 50px;
    width: 250px;

    top: 5px;
    z-index: 5;

    transition: right 500ms ease;

    &.open {
        right: 0px !important;
    }

    &:not(.open) {
        .cogs-settings:hover {
            background: lighten($primary-two, 10%);
            color: #e4e4e4;
            right: 0;
            width: 40px;
            padding-right: 5px;
            margin-left: 0;
        }
    }
}

.file {
    font-size: 15px;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-bottom: 10px;
}

.delete-file-icon {
    width: 25px;
    display: inline-block;

    &:hover {
        color: $danger;
    }
}

.bottom-dash {
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
    background-size: 4px 100%;
    background-repeat: repeat-x;
    width: 100%;
    height: 1px;
}

.cogs-settings {
    border-bottom: 1px solid #302f2f;
    background: $primary-two;
    color: $primary-three;
    text-align: center;
    padding: 0;
    width: 36px;
    height: 28px;
    display: block;

    border-radius: 5px 0 0 5px;
    cursor: pointer;
    font-size: 19px;
    transition: all 0.1s linear 0s;
    margin-left: 4px;
}

.cogs-settings-container {
    min-height: 50px;
    height: 100%;
    width: 100%;

    border-radius: 0 0 0 5px;
    background: $primary-two;
    font-size: 19px;

    padding: 10px;

    *:not(input) {
        color: #ffffff;
    }
}
// temporary fix until all components, have been migrated to ngMaterial
.mat-form-field {
    bottom: -9px;
}

.datepickers-container .datepicker {
    z-index: 999999999 !important;
}

header {
    display: block;
    border-bottom: 1px dashed rgba(0, 0, 0, 0.2);
    background: #fff;
    font-size: 23px;
    font-weight: 300;
    color: #232323;
    margin-bottom: 10px;
}

body,
html {
    margin: 0;
    height: 100%;
    font-family: Roboto, 'Helvetica Neue', sans-serif;
    background-color: #ffffff;
    overflow-x: hidden;

    * {
        @include scrollbar();
    }
}

.user-select-none {
    user-select: none;
}

body {
    .modal-backdrop.show {
        opacity: 0.5 !important;
    }

    &.backdrop-opened {
        li {
            &.active a.active:before {
                border-color: transparent #dddddd transparent transparent !important;
            }
        }
    }

    &.modal-open {
        toast {
            z-index: 1052 !important;
        }
    }
}

.bg-image {
    background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAAAAAA6mKC9AAAAQElEQVQY03XMwQ0AIQgAQfqvc7eOu4cQlSAvsw7El6OqGBVQ0CvoGZQ7DGIIjKIe9RF5/AhdJKAL3ivrKE2wxQ+P6/Hxk9140AAAAABJRU5ErkJggg==');
}

.text-underline {
    text-decoration: underline !important;
}

.anchor-link {
    color: $primary-two !important;
}

.truncate {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

table {
    td {
        .label-text span:after {
            top: -3px;
        }
    }
}

.ag-header-icon.ag-filter-icon,
.ag-theme-balham .ag-icon-filter {
    display: none !important;
}

.ag-theme-balham .ag-header-cell-label .ag-header-icon {
    &[ref='eFilter'] {
        display: none !important;
    }
}

toast {
    z-index: 2;
}

.cursor-disabled {
    cursor: none !important;
}

app-layout {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;

    toast {
        z-index: 10;
    }

    .layout-container {
        width: 100%;
    }

    font-size: 0.875rem;

    .bd-placeholder-img {
        font-size: 1.125rem;
        text-anchor: middle;
    }

    /*
   * Content
   */

    [role='main'] {
        overflow-y: auto;
        overflow-x: hidden;
        padding-right: 0;
        padding-left: 0;
        flex-grow: 1;
    }
}

// .border-left-50-grey {
//     border-left: thin solid $xenial-50-grey;
// }

// .header-toolbar-right {
//     height: 100%;

//     .btn-group {
//         height: 100%;
//     }

//     a {
//         display: flex;
//         align-items: center;
//         justify-content: center;
//         color: #53565a;
//         height: 100%;
//         min-width: 60px;
//         padding: 0 8px;
//         position: relative;
//         border-radius: 62px !important;

//         &:not(:last-child) {
//             margin-right: 2px;
//         }

//         &:focus,
//         &:active {
//             box-shadow: none;
//         }

//         .badge {
//             position: relative;
//             top: -4px;
//             left: 2px;
//             padding-right: 5px;
//             padding-left: 5px;
//         }
//     }
// }

// .primary-header-height {
//     height: $header-height;
// }

// .primary-header {
//     background-color: #ffffff;
// }

// app-headerbar {
//     display: flex;

//     .site-group {
//         border-right: thin solid $xenial-50-grey;
//         border-left: thin solid $xenial-50-grey;
//     }

//     .site-icon {
//         width: 35px;
//         padding: 0 5px;
//         text-align: center;
//         vertical-align: middle;
//         margin-top: 10px;
//         font-size: 25px;
//         display: table;
//         color: $xenial-50-grey;

//         + .site-description {
//             width: 150px;
//         }
//     }

//     .site-description {
//         text-align: left;
//         padding: 6px 0;
//         padding-right: 5px;
//         vertical-align: middle;

//         .site_name {
//             font-weight: bold;
//         }
//     }
// }

@import '~@fortawesome/fontawesome-free/scss/fontawesome';
@import '~@fortawesome/fontawesome-free/scss/solid';
@import '~@fortawesome/fontawesome-free/scss/brands';
@import '~@fortawesome/fontawesome-free/scss/regular';

