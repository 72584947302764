
.btn-sm, .btn-group-sm > .btn {
  padding: 0 0.5rem;
  font-size: 11px;
  line-height: 2;
  border-radius: 0.2rem;
}

button.btn {
  max-height: 34px;

}

.btn {

  border-radius: 0px;


  // Bring the hover, focused, and "active" buttons to the front to overlay
  // the borders properly
  @include hover {
    z-index: unset;
  }

  &:focus,
  &:active,
  &.active {
    z-index: unset;
    box-shadow: none;
  }
}
