.table-bordered,
.table-bordered > tbody > tr > td,
.table-bordered > tbody > tr > th,
.table-bordered > tfoot > tr > td,
.table-bordered > tfoot > tr > th,
.table-bordered > thead > tr > td,
.table-bordered > thead > tr > th {
  border: 1px solid #ddd !important;
}

table {
  background-color: transparent;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

.fc-border-separate thead tr,
.table thead tr {
  background-color: #eee;
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#f2f2f2), to(#fafafa));
  background-image: -webkit-linear-gradient(top, #f2f2f2 0, #fafafa 100%);
  background-image: -moz-linear-gradient(top, #f2f2f2 0, #fafafa 100%);
  background-image: -ms-linear-gradient(top, #f2f2f2 0, #fafafa 100%);
  background-image: -o-linear-gradient(top, #f2f2f2 0, #fafafa 100%);
  background-image: -linear-gradient(top, #f2f2f2 0, #fafafa 100%);
  font-size: 14px;

  th {
    font-weight: normal;
  }
}

.table > caption + thead > tr:first-child > td,
.table > caption + thead > tr:first-child > th,
.table > colgroup + thead > tr:first-child > td,
.table > colgroup + thead > tr:first-child > th,
.table > thead:first-child > tr:first-child > td,
.table > thead:first-child > tr:first-child > th {
  border-top: 0;
}

.table-bordered > thead > tr > td, .table-bordered > thead > tr > th {
  border-width: 1px;
}

.table > tbody > tr > td, .table > tbody > tr > th,
.table > tfoot > tr > td, .table > tfoot > tr > th,
.table > thead > tr > td, .table > thead > tr > th {
  padding: 8px 10px;
}

.table-bordered,
.table-bordered > tbody > tr > td,
.table-bordered > tbody > tr > th,
.table-bordered > tfoot > tr > td,
.table-bordered > tfoot > tr > th,
.table-bordered > thead > tr > td,
.table-bordered > thead > tr > th {
  border: 1px solid #ddd;
}

.table > tbody > tr > td,
.table > tbody > tr > th,
.table > tfoot > tr > td,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > thead > tr > th {
  line-height: 1.42857143;
  vertical-align: top;
  border-top: 1px solid #ddd;
}
