@media (min-width: 768px) {

  .modal-content {
    -webkit-box-shadow: 0 5px 15px rgba(0, 0, 0, .5);
    box-shadow: 0 5px 15px rgba(0, 0, 0, .5);
  }


}


.modal-content {
  position: relative;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, .2);
  border-radius: 3px;
  -webkit-box-shadow: 0 3px 9px rgba(0, 0, 0, .5);
  box-shadow: 0 3px 9px rgba(0, 0, 0, .5);
  background-clip: padding-box;
  outline: 0;
  position: relative;

  .modal-header {
    padding: 10px;
    border-bottom: 1px solid #e5e5e5;
    min-height: 16.43px;

    .close {
      position: absolute;
      margin: 0;
      right: 8px;
      top: 8px;
      cursor: pointer;


      &:before {
        content: '×';
        position: absolute;
        right: 8px;
        top: 4px;
      }
    }


    .close {
      float: right;
      font-size: 19.5px;
      font-weight: 700;
      line-height: 1;
      color: #000;
      text-shadow: 0 1px 0 #fff;
      opacity: .2;
      filter: alpha(opacity=20);
    }


    .modal-title {
      margin: 0;
      line-height: 1.42857143;
    }

  }


  .modal-body {
    position: relative;
    padding: 10px;
  }

  .modal-footer {
    padding: 10px;
    text-align: right;
    border-top: 1px solid #e5e5e5;
  }
}


.d-flex > .modal-content {
  flex-grow: 1;
}

.min-height-80 {
  min-height: 80% !important;
}


.modal-80-percent {
  max-width: 80% !important;
  width: 80% !important;

  @media (min-width: $theme-breakpoint) {
    max-width: 90% !important;
    width: 90% !important;
  }

}

.modal-85-percent {
  max-width: 85% !important;
  width: 85% !important;
}

.modal-90-percent {
  max-width: 90% !important;
  width: 90% !important;
}

.modal-600-px {
  max-width: 600px !important;
  width: 600px !important;
}


.modal-400-px {
  max-width: 400px !important;
  width: 400px !important;
}

@media (min-width: 575px) and (max-width: $theme-breakpoint) {
  .modal-lg {
    max-width: 90% !important;
    width: 90% !important;
  }
}

.modal-lg {
  min-width: 630px;

  @media (min-width: 961px) and (max-width: 991px) {
    min-width: 800px;

  }

}

.modal-header-flush .modal-header {
  border-bottom: none;
  padding: 0;
}

.mw-600-px {
  max-width: 600px !important;
}

.mw-750-px {
  max-width: 750px !important;
}

.mw-850-px {
  max-width: 850px !important;
}

.mw-900-px {
  max-width: 900px !important;
}


.mw-950-px {
  max-width: 950px !important;
}
