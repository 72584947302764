@import 'src/scss/material-design/variables';

$primary-one: rgb(200, 16, 46) !default;
$primary-two: rgb(83, 86, 90) !default;
$primary-three: rgb(167, 168, 170) !default;

//PRIMARIES
$primary-one: $primary-one;
$xenial-blue: $primary-two;
$xenial-lake: $primary-two;
$xenial-sky: transparent !default;
$xenial-lime: transparent !default;
$xenial-navy: $xenial-blue;

//XENIAL GREYS

$xenial-charcoal: $primary-two;
$xenial-50-grey: lighten($primary-two, 50%);
$xenial-30-grey: lighten($primary-two, 30%);
$xenial-07-grey: lighten($primary-two, 7%);
$xenial-white: rgb(255, 255, 255) !default;
$xenial-black: rgb(68, 68, 68) !default;

$freshtxt-red: #b5302e;
$freshtxt-green: #dcf476;
$freshtxt-dark-green: #9ab72f;

$tangerine: #f35b3f;

// Theme;
$theme-name: 'Admin Html';
$theme-slug: 'admin-html';
$theme-breakpoint: 960px;
$transition-duration: 0.3s;
$transition-function: ease-in-out;
$spacing-unit: 24px;

// Content
$wrapper-top-padding: 25px;

// Header
$logo-width: 3.125rem; // 60px
$nav-height: 3.125rem; // 60px
$nav-link-color: #888;
$nav-link-hover-color: #f7fafc;
$nav-sub-link-color: #888;
$nav-sub-link-hover-color: #514d6a;
$nav-icon-color: #d0d6df;

// Sidebar
$sidebar-width: 220px;
$sidebar-collapse-width: 60px;
$sidebar-bg: #fff;

//Right Sidebar
$right-sidebar-width: $sidebar-width;

// COMPONENTs heights
$footer-height: 34px;
$header-height: 70px;

//
// Color system
//

// Theme Colors
$color-scheme: $primary-two !default;
$color-scheme-hover: darken($color-scheme, 7%) !default;
$color-scheme-dark: $primary-three !default;
$color-scheme-dark-hover: #4a5368 !default;
$content-color: #555 !default;
$theme-border-color: #c2c2c2 !default;

// Variables
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.

//
// Color system
//

$white: #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black: #000 !default;

$grays: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$grays: map-merge(
    (
        '100': $gray-100,
        '200': $gray-200,
        '300': $gray-300,
        '400': $gray-400,
        '500': $gray-500,
        '600': $gray-600,
        '700': $gray-700,
        '800': $gray-800,
        '900': $gray-900,
    ),
    $grays
);

$blue: #007bff !default;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #e83e8c !default;
$red: #e6614f !default;
$orange: #fb9678 !default;
$yellow: #ffcc02 !default;
$green: #38d57a !default;
$teal: #20c997 !default;
$cyan: #03a9f3 !default;

$primary: #fb9678 !default;
$danger: #c26565 !default;
$success: #38d57a !default;
$warning: #ffcc02 !default;
$info: #03a9f3 !default;

$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
) !default;

$transition-base: all 0.2s ease-in-out !default;
$transition-fade: opacity 0.15s linear !default;
$transition-collapse: height 0.35s ease !default;

// Fonts
//
// Font, line-height, and color for body text, headings, and more.

$secondary-font-family: 'Arial', sans-serif;
$tertiary-font-family: 'Helvetica', sans-serif;
$font-family-sans-serif: 'Arial', 'Helvetica', sans-serif !default;
$font-family-base: $font-family-sans-serif !default;

$font-size-base-px: 14px !default;
$font-size-base: 0.875rem !default;
$font-size-lg: 1rem !default; // Assumes the browser default, typically `16px`
$font-size-sm: 0.75rem !default;
$font-size-xs: 0.625rem !default;

$font-weight-normal: normal !default;
$font-weight-bold: bold !default;

$font-weight-base: $font-weight-normal !default;
$line-height-base: 1.57142857 !default;

$grey-color-2: $xenial-30-grey;
$grey-color: $xenial-50-grey;
$transitions-speed: 500ms;
$font-color: #444444 !default;
$font-color-base: #444444 !default;
$primary-darken: darken($primary-one, 10%);
$header-height: 60px;
$secondary-header-height: 42px;
$lighten-grey: lighten($primary-three, 5%);

.focus-outline-none {
    &:focus {
        outline: none;
    }
}

