.widget-bg {
  padding: 1.42857143em;
  background: #fff;
}

.widget-list {
  padding-top: 25px;
}

.widget {
  background-color: #fff;
  color: #333;
  border: 1px solid #C2C2C2;

  > header {
    border-bottom: 1px solid #C2C2C2;
    background: #fafafa;
    padding: 4px 10px;
    font-size: 18px;

    > fa-icon {
      margin-right: 5px;
    }
  }
}
