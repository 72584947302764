@mixin breakpoint-sidebar {
    .hide-menu {
        transition: opacity 0.5s;
        opacity: 0;
    }

    .badge {
        display: none;
    }

    &.site-sidebar {
        width: $sidebar-collapse-width;
        overflow: visible;
    }

    li:not(:hover) {
        &:before {
            width: $sidebar-collapse-width !important;
        }
    }

    .side-menu {
        .sub-menu {
            max-height: 0px;

            li {
                display: none;
                transition-name: display;
                transition-delay: $transitions-speed;
            }

            li.active:hover {
                width: unset !important;
            }
        }

        li {
            &:not(.collapse-control):not(.is-child) {
                &:hover {
                    width: em(279, 13);
                    border-right: thin solid #ccc;
                    background: #eee;
                    z-index: 10;

                    &:after {
                        content: '';
                        border-bottom: thin solid #cccccc;
                        width: 100% !important;
                        pointer-events: none;
                        position: absolute;
                        height: calc(100% + 1px);
                        top: 0;
                    }

                    &:not(:first-child) {
                        &:after {
                            border-top: thin solid #cccccc;
                        }
                    }

                    > a .hide-menu {
                        opacity: 1;
                    }

                    .sub-menu {
                        display: block;
                        pointer-events: all;
                        max-height: unset;
                        position: absolute;
                        left: calc(#{$logo-width} + 9px);
                        width: em(220, 13);
                        margin-left: 0;
                        padding: 8px 0;
                        background: #fff;
                        box-shadow: 0 4px 0 0 rgba(204, 204, 204, 0.75);
                        border-right: 1px solid #ccc;
                        border-bottom: 1px solid #ccc;
                        border-left: 1px solid #ccc;

                        li {
                            display: block;

                            > a {
                                text-overflow: unset;
                            }

                            //&:hover,
                            //&.active {
                            //  //background: #eee !important;
                            //
                            //  > a {
                            //    color: #00467f !important;
                            //  }
                            //}
                        }
                    }
                }
            }
        }
    }
}
